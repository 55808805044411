import React, {  useEffect, useState } from 'react'
import { Document, Page } from './Pdf'
import { TiZoom } from "react-icons/ti";
import { IoIosCloseCircle } from "react-icons/io";
import { MdOpenInNew } from "react-icons/md";

  
interface CustomPdfViewerProps{
    fileContent: string
    onDocumentLoadSuccess: (pdfDoc)=> void
    onNextPage: ()=>void
    onPreviousPage: ()=>void
    onDocumentLoadError,
    pdfNumPages: number,
    pdfPageNumber: number,
    pdfDoc
}
const DEFAULT_PDF_HEIGHT = 500
export const CustomPdfViewer = ({
        onNextPage,
        onPreviousPage,
        fileContent, 
        onDocumentLoadSuccess, 
        pdfNumPages, pdfPageNumber, pdfDoc, onDocumentLoadError}: CustomPdfViewerProps)=>{
    const [showModal, setShowModal] = useState(false);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [zoom, setZoom] = useState(1);

    useEffect(()=>{
        const newHeight = DEFAULT_PDF_HEIGHT * zoom
        document.documentElement.style.setProperty('--pdfHeight', `${newHeight}px`);
    }, [zoom])



  const modalStyle = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: showModal ? '' : 'none',
    zIndex: '999',
  };

  const modalMainStyle = {
    position: 'fixed',
    background: 'white',
    maxWidth: `${windowWidth * 0.8}px`, 
    maxHeight: `${windowHeight * 0.8}px`, 
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
    display: showModal ? '' : 'none',
  };

    const openModal = () => {
        setShowModal(true);
        updateWindowSize();
    };

    const updateWindowSize = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };

    const closeModal = () => {
        setZoom(1)
        setShowModal(false);
    };
    const onZoom = (e: any) => {
        const zoom = parseFloat(e.target.value);
        setZoom(zoom)
        
    };
    return <>
        <Document
            data={fileContent}
            onLoadError={onDocumentLoadError}
            onLoadSuccess={onDocumentLoadSuccess} >
            
            <Page pdfDoc={pdfDoc} pageNumber={pdfPageNumber} />
            
        </Document>
        <div style={modalStyle}>
            <div style={modalMainStyle}>
                {pdfNumPages > 0 && (
                    <PdfToolbar 
                        onNextPage={onNextPage} 
                        onPreviousPage={onPreviousPage} 
                        pdfNumPages={pdfNumPages} 
                        pdfPageNumber={pdfPageNumber} 
                        onClose={closeModal} 
                        onZoom={onZoom}
                        />
                )}
                <Document
                    data={fileContent}
                    onLoadError={onDocumentLoadError}
                    onLoadSuccess={onDocumentLoadSuccess} >
                    
                    <Page pdfDoc={pdfDoc} pageNumber={pdfPageNumber}  scale={1} />
                    
                </Document>
                
            </div>
        </div>
        {pdfNumPages > 0 && (
            <PdfToolbar 
                onNextPage={onNextPage} 
                onPreviousPage={onPreviousPage} 
                pdfNumPages={pdfNumPages} 
                pdfPageNumber={pdfPageNumber} 

                onOpen={openModal}
                 />
        )}
</>
}

interface PdfToolbarProps {
    pdfPageNumber: number
    pdfNumPages: number
    onNextPage: ()=>void
    onPreviousPage: ()=>void
    onClose?: ()=> void
    onZoom? (e : any)
    onOpen?: ()=>void
}
const zoomOptions = [1, 1.5]; 
const PdfToolbar = ({pdfPageNumber, pdfNumPages, onNextPage, onPreviousPage, onClose, onZoom, onOpen}: PdfToolbarProps)=>{
    return <div style={{display: 'flex', alignItems: 'center',  justifyContent: 'space-between',}}>
    
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: 10,
        paddingLeft: 10
    }}>
        <div>
            Page {pdfPageNumber} / {pdfNumPages}
        </div>
        <button style={{marginRight: 2, marginLeft: 20}} onClick={onPreviousPage}>{"<"}</button>
        <button style={{marginLeft: 2}} onClick={onNextPage}>{">"}</button>
        
        
        {onZoom && (
            <div style={{marginLeft: 40}}>
                <TiZoom />
                <select onChange={onZoom}>
                    {zoomOptions.map((option, index) => (
                    <option key={index} value={option}>
                        {`X${option}`}
                    </option>
                    ))}
                </select>
            </div>
            )}
    </div>
    {onClose ? 
        <div onClick={onClose} style={{marginRight: 10, cursor: 'pointer'}} ><IoIosCloseCircle /></div> : 
        <div onClick={onOpen} style={{marginRight: 10, cursor: 'pointer'}}><MdOpenInNew /></div>
    }
    </div>
}
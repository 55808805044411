import React, { ReactNode, useRef, useEffect, useState, useCallback } from "react";

interface DocumentProps {
    children?: ReactNode,
    data: string,
    onLoadError?: (err: String) => void,
    onLoadSuccess?: (pdfDoc: any) => void,
}

export function Document(props: DocumentProps) {
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [pdfLoading, setPdfLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        var tag = document.createElement('script');
        tag.async = true;
        tag.src = "/js/pdfjs/pdf.js";
        tag.onload = () => { 
            setScriptLoaded(true) 
            console.log('script loaded')
        };
        var body = document.getElementsByTagName('body')[0];
        body.appendChild(tag);
    }, [])


   
    useEffect(()=>{
        if (!scriptLoaded) return
        const pdfjsLib = window['pdfjs-dist/build/pdf'];
        pdfjsLib.GlobalWorkerOptions.workerSrc = "/js/pdfjs/pdf.worker.js";
        
        const loadingTask = pdfjsLib.getDocument({ data: atob(props.data) });
        loadingTask.promise
            .then((pdfDoc: any) => {
                setPdfLoading(false)
                if (props.onLoadSuccess) {
                    props.onLoadSuccess(pdfDoc);
                }
            })
            .catch((err: Error) => setError(err.message));
    
    }, [scriptLoaded, props.data])

    if (error !== "") {
        console.log(error);
        return (
            <p className="text-danger text-center">{error}</p>
        )
    }

    if (!scriptLoaded) {
        return (
            <p className="text-secondary text-center">initialisation...</p>
        )
    }

    
    if (pdfLoading) {
        return (
            <p className="text-secondary text-center">chargement...</p>
        )
    }

    return (
        <>
            {props.children}
        </>
    )
}

interface PageProps {
    pageNumber: Number,
    pdfDoc: any,
    scale?: number
}

export function Page({ scale = 0.8,pdfDoc, pageNumber}: PageProps) {
    const canvasRef = useRef<HTMLCanvasElement>(null)


    const renderPage = useCallback((ctx: CanvasRenderingContext2D | null)=>{
        if (!ctx) {
            console.error("ctx is null");
            return;
        }

        if (!pdfDoc) {
            ctx.fillStyle = '#000000'
            ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)
            return;
        }


        pdfDoc.getPage(pageNumber)
            .then(page => {
                console.log(`page: %o`, page);

                const viewport = page.getViewport({ scale: scale });
                if (ctx.canvas){
                    ctx.canvas.height = viewport.height;
                    ctx.canvas.width = viewport.width;
                }
                const renderContext = {
                    canvasContext: ctx,
                    viewport: viewport
                };

                var renderTask = page.render(renderContext);
                renderTask.promise.then(_ => {
                    console.log('Page rendered');
                });
            })
            .catch(err => {
                console.error(`getPage error ${err}`);
            });
        
    }, [pdfDoc, pageNumber])
    
    useEffect(()=>{
        const canvas = canvasRef.current;
        if (!canvas) {
            console.error("canvas is null");
            return;
        }
        let ctx = canvas.getContext('2d');
        if (!ctx) {
            console.error("ctx is null");
            return;
        }
        renderPage(ctx)

    }, [  pageNumber, pdfDoc.fingerprints.reduce((acc, value)=>acc+value)])


    return (
        <canvas id="pdfcanvas" ref={canvasRef}></canvas>
    )
}

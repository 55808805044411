import React, { useEffect, useState, useRef } from "react";
import { api_create, api_get_file_types, api_set_file_type, api_browse, api_open, DirContent, api_rename, api_download, api_remove } from "./api";
import Table from 'react-bootstrap/Table';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Pagination from 'react-bootstrap/Pagination';
import { Document, Page } from '../pdf/Pdf';
import Moment from 'moment';
import { RiHome2Fill, RiFileList2Line, RiFolderFill, RiFolderForbidFill } from "react-icons/ri";
import { pathExplode, pathParent, pathBase, pathJoin, pathExt } from "../../utils/path";
import Select from 'react-select';
import { CustomPdfViewer } from "../pdf/CustomPdfViewer";

// todo: put this into a database
const defaultFileTypes = {
    "deceased.death_cerficate": "certificat de décès",
    "deceased.cni": "carte identité défunt",
    "customer.cni": "carte identité client",
    "bank_rib": "RIB client",
    "good_for_agreement": "bon pour accord",
    "assignment_form": "formulaire de cession",
    "mandat": "mandat",
    "driver.license": "permis de conduire",
    "other": "Autre type de document",
    "good_for_agreement.signed": "bon pour accord signé",
    "assignment_form.signed": "formulaire de cession signé",
    "mandat.signed": "mandat signé",
    "neotouch": "documents envoyés via neotouch",
    "yousign": "documents envoyés via yousign",
    "neotouch.signed": "documents signés via neotouch",
    "yousign.signed": "documents signés via yousign",
}

interface Border {
    id: string;
    cn: string;
}

interface FileToUpload {
    subdir: string;
    file: File;
}

const itemsPerPageOptions = [
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
];

const queryParams = new URLSearchParams(window.location.search);
const userName = queryParams.get("user") || "";
const token = queryParams.get("token") || "";
const rootDir = queryParams.get("root") || "/";
const showTypes = queryParams.get("show-types") == "true";
const showUpload = queryParams.get("show-upload") == "true";

interface TempError {
    error: string,
    timeout: number,
    warning: boolean,
}

function Browse() {
    const uploadRef = useRef(null);
    const uploadInputRef = useRef(null);

    const [border, setBorder] = useState<Border | null>(null);
    const [currentDir, setCurrentDir] = useState<String>(rootDir);
    const [currentPage, setCurrentPage] = useState<Number>(0);
    const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
    const [currentFile, setCurrentFile] = useState<String>("");
    const [fileContent, setFileContent] = useState<String | null>(null);
    const [dirContent, setDirContent] = useState<DirContent[]>([]);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(0);
    const [fileError, setFileError] = useState(0);
    const [pdfNumPages, setPdfNumPages] = useState(0);
    const [pdfPageNumber, setPdfPageNumber] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [pdfDoc, setPdfDoc] = useState<any | null>(null);
    const [fileList, setFileList] = useState([] as string[]);
    const [editFile, setEditFile] = useState("");
    const [newName, setNewName] = useState("");
    const [fileTypes, setFileTypes] = useState({});
    const [droppedFiles, setDroppedFiles] = useState([] as FileToUpload[]);
    const [uploadingFile, setUploadingFile] = useState<FileToUpload | null>(null);
    const [fileProgress, setFileProgress] = useState(0);
    const [unzipingFile, setUnzipingFile] = useState("");
    const [removingFiles, setRemovingFiles] = useState(0);
    const [temporaryErrorList, setTemporaryErrorList] = useState([] as TempError[]);

    const tmp = currentDir.trim()
    if (tmp != currentDir) {
        console.log('currentDir trimmed');
        setCurrentDir(tmp);
    }

    useEffect(() => {
        const popstate = (event) => {
            if (event.state && event.state.old) {
                setCurrentDir(event.state.old);
            } else {
                setCurrentDir(rootDir);
            }
        };
        window.addEventListener("popstate", popstate);
        return () => {
            window.removeEventListener("popstate", popstate);
        }
    }, []);

    const addTemporaryError = (errText: string, timeout: number, warning: boolean = false) => {
        setTemporaryErrorList((oldList) => {
            oldList.push({
                error: errText,
                timeout: timeout,
                warning: warning,
            });
            return oldList;
        });
    }

    if (false) {
        useEffect(() => {
            const currentEl = uploadRef.current;
            if (currentEl === null) {
                console.log(`uploadRef is null`);
                return;
            }
            const uploadEl = currentEl as HTMLDivElement;
            console.log(`uploadRef %o`, uploadEl);

            const move = (evt: MouseEvent) => {
                const rect = uploadEl.getBoundingClientRect();
                console.log(`mouse: ${evt.clientX}x${evt.clientY}-${rect.width}x${rect.height}`);
            }

            uploadEl.addEventListener('mousemove', move);
            return () => {
                uploadEl.removeEventListener('mousemove', move);
            }
        });
    }

    useEffect(() => {
        setInterval(() => {
            if (temporaryErrorList.length === 0) {
                return;
            }
            setTemporaryErrorList((oldList) => {
                const newList = [] as TempError[];
                for (let i = 0; i < oldList.length; i++) {
                    oldList[i].timeout -= 100;
                    if (oldList[i].timeout > 0) {
                        newList.push(oldList[i]);
                    }
                }
                return newList;
            });
        }, 200);
    }, []);

    useEffect(() => {
        console.log(`dropped-files: list contains ${droppedFiles.length} entries`);
        if (droppedFiles.length > 0) {
            const fileToUpload = droppedFiles[0];
            console.log(`dropped-files: processing %o`, fileToUpload);
            if (fileToUpload) {
                setUploadingFile(fileToUpload);
                setFileProgress(0);
            }
        } else {
            console.log(`dropped-files: no file to upload`);
        }
        console.log(`dropped-files: done`);
        return () => {
            console.log("dropped-files: cleanup");
        }
    }, [droppedFiles]);

    useEffect(() => {
        console.log(`uploading-file: uploadingFile=${uploadingFile}`);
        if (uploadingFile === null) {
            return;
        }

        const dirs = pathExplode(currentDir).slice(1);
        if (uploadingFile.subdir !== "") {
            dirs.push(uploadingFile.subdir);
        }
        const subdir = dirs.join("/");

        const data = new FormData();
        data.append("kind", "other");
        data.append("sdir", subdir);
        data.append("file", uploadingFile.file);
        data.append("user", userName);
        data.append("size", `${uploadingFile.file.size}`);

        if (uploadingFile.file.size == 0) {
            addTemporaryError(`attention, le fichier ${uploadingFile.file.name} a une taille nulle`, 4000, true);
        }

        const fileName = uploadingFile.file.name;

        const xhr = new XMLHttpRequest();
        let dir = `${currentDir}`.slice(1);
        let tmp = dir.split("/")[0].split("-").slice(-1)[0];
        const uri = `/api/v1/browse/${tmp}/files/?token=${token}`;
        console.log(`uploading-file: opening uri ${uri}`);
        xhr.open('POST', uri);
        xhr.onreadystatechange = (evt) => {
            console.log(`uploading-file: readyState=${xhr.readyState}`);
            if (xhr.readyState == xhr.DONE) {
                console.log(`uploading-file: got status: ${xhr.status}`);
                setUploadingFile(null);
                if (xhr.status == 200) {
                    const data = JSON.parse(xhr.response);

                    const newDirContent = [...dirContent];
                    const newPath = data.file_path.replaceAll("\\", "/");
                    newDirContent.push({
                        name: `${pathBase(`/${newPath}`)}`,
                        size: data.size,
                        last_mod: new Date(),
                        is_dir: false,
                        can_read: true,
                    })
                    setDirContent(newDirContent);
                    if (data.zip_id >= 0) {
                        setUnzipingFile(fileName);
                        let n = 300;
                        let timer = setInterval(() => {
                            if (n == 0) {
                                clearInterval(timer);
                                setUnzipingFile("");
                                return;
                            }
                            n -= 1;

                            console.log(`${data.zip_id}: n=${n}`);
                            const xhr = new XMLHttpRequest();
                            const uri = `/api/v1/browse/zip/${data.zip_id}?token=${token}`;
                            xhr.open('GET', uri);
                            xhr.onreadystatechange = (evt) => {
                                console.log(`uploading-file: readyState=${xhr.readyState}`);
                                if (xhr.readyState == xhr.DONE) {
                                    if (xhr.status != 200) {
                                        clearInterval(timer);
                                        setUnzipingFile("");
                                        return;
                                    }
                                    const data = JSON.parse(xhr.response);
                                    if (data.done) {
                                        clearInterval(timer);
                                        setUnzipingFile("");
                                    }
                                    let updated = false;
                                    data.files.forEach((file) => {
                                        let found = false;
                                        newDirContent.forEach((entry) => {
                                            let name = entry.name;
                                            if (name == file.name || name == `/${file.name}`) {
                                                found = true;
                                            }
                                        });
                                        if (!found) {
                                            updated = true;
                                            console.log(`adding file ${file.name} ${file.size}`);
                                            newDirContent.push({
                                                name: `${pathBase(`/${file.name}`)}`,
                                                size: file.size,
                                                last_mod: new Date(),
                                                is_dir: false,
                                                can_read: true,
                                            })
                                        }
                                    });
                                    if (updated) {
                                        setDirContent(newDirContent);
                                    }
                                }
                            }
                            xhr.send();
                        }, 1000);
                    }
                } else {
                    switch (xhr.status) {
                        default:
                            addTemporaryError(`${fileName}: erreur ${xhr.status}`, 3000);
                            break;
                        case 0:
                            addTemporaryError(`${fileName}: erreur de réseaux`, 3000);
                            break;
                    }
                }
                setDroppedFiles([...droppedFiles].slice(1));
            }
        }
        xhr.onerror = (evt) => {
            console.error(`uploading-file: got error: %o`, evt);
            // setTemporaryError(`${fileName}: erreur d'envoi`, 2000);
        }
        xhr.upload.onprogress = (evt) => {
            if (evt.lengthComputable) {
                console.log(`uploading-file: loaded=${evt.loaded} / total=${evt.total}`);
                setFileProgress((evt.loaded * 100.0) / evt.total);
            } else {
                // animate
                console.log(`uploading-file: length not computable`);
            }
        }
        console.log(`uploading-file: sending data`);
        xhr.send(data);
        return () => {
            console.log(`unmounting uploader`);
        }
    }, [uploadingFile]);

    useEffect(() => {
        setCurrentFile("");
        setCurrentPage(0);
        setSearchText("");
        setLoaded(false);
        console.log(`new-dir: ${currentDir} [parent=${pathParent(currentDir)}]`);
        api_browse(currentDir, userName)
            .then(content => {
                setLoaded(true);
                setError(0);
                setDirContent(content);
            })
            .catch(err => {
                setLoaded(true);
                setError(err);
                setDirContent([]);
            })
            ;
        const dir = `${currentDir}`.slice(1);
        const accountNumber = dir.split("/")[0].split("-").slice(-1)[0];
        api_get_file_types(accountNumber)
            .then(file_types => {
                setFileTypes(file_types);
            })
            .catch(err => {
                setError(err);
            })
            ;
    }, [currentDir]);

    useEffect(() => {
        console.log(`selected-file: ${currentFile}`);
        if (currentFile !== "") {
            api_open(currentFile, userName)
                .then(content => {
                    // console.log(content);
                    setFileContent(content);
                    setFileError(0);
                })
                .catch(err => {
                    setFileContent(null);
                    setFileError(err);
                })
                ;
        }
    }, [currentFile]);

    if (!loaded) {
        return (
            <div className="container text-center">
                <p>Loading ...</p>
            </div>
        )
    }

    function browse(name: String, src: String = "unknown") {
        return (evt: React.MouseEvent) => {
            console.log(`browsing to ${name} [${src}]`);
            if (evt) {
                evt.preventDefault();
            }
            if (history && history.pushState) {
                history.pushState({
                    old: name
                }, "" + name, 
                    window.location.pathname +
                    '?token=' + token +
                    '&user=' + userName +
                    '&root=' + name)
            }
            setCurrentDir(name);
            setFileList([]);
        }
    }

    function updateList(dir, entry) {
        const name = pathJoin(dir, entry.name);
        return (evt: React.ChangeEvent) => {
            const newList = fileList.filter(tmp => tmp != name);
            if (newList.length >= fileList.length) {
                newList.push(`${name}`);
            }
            setFileList(newList);
        }
    }

    function clickRenameFile(dir, entry) {
        const name = pathJoin(dir, entry.name);
        return (evt: React.MouseEvent) => {
            setNewName(entry.name);
            setEditFile(`${name}`);
        }
    }

    function onBlur(_event: React.FocusEvent<HTMLInputElement>) {
        setEditFile("");
    }

    function onInputKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        switch (event.key) {
            case "Escape":
                setEditFile("");
                break;

            case "Enter":
                console.log(`renaming ${editFile} to ${newName}`);
                api_rename(editFile, newName, userName)
                    .then(() => {
                        filteredContent.slice(indexBegin, indexEnd).map((entry, entryIndex) => {
                            if (pathJoin(currentDir, entry.name) == editFile) {
                                entry.name = newName;
                            }
                        })
                        setEditFile("");
                    })
                    .catch((error) => {
                        setEditFile("");
                        alert(`- could not rename ${editFile} to ${newName}, error ${error}`);
                    })
                break;
        }
    }

    function onUpdateNewName(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        setNewName(value);
    }

    function openFile(dir, entry) {
        const name = pathJoin(dir, entry.name);
        return (evt: React.MouseEvent) => {
            if (name == currentFile) {
                setCurrentFile("");
            } else {
                evt.preventDefault();
                setCurrentFile(name);
            }
        }
    }

    const entries = pathExplode(currentDir);

    function goToPage(index: Number) {
        return (evt: React.MouseEvent) => {
            setCurrentFile("");
            setCurrentPage(index);
        }
    }

    const indexBegin = currentPage.valueOf() * itemsPerPage.value;
    const indexEnd = indexBegin + itemsPerPage.value;

    function canRead(entry) {
        return entry.can_read
    }

    const filteredContent = dirContent.filter((entry) => {
        const name = entry.name;
        return name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0;
    });

    function my_strcasecmp(a, b) {
        if ((a + '').toLowerCase() > (b + '').toLowerCase()) return 1
        if ((a + '').toLowerCase() < (b + '').toLowerCase()) return -1
        return 0
    }

    filteredContent.sort((a: DirContent, b: DirContent): number => {
        return my_strcasecmp(a.name, b.name);
    });
    // console.log(filteredContent);

    const pageCount = Math.floor(filteredContent.length / itemsPerPage.value) + 1;

    const tabCount = 8;
    const tabHalf = Math.floor(tabCount / 2);

    let pgCurr = currentPage.valueOf();
    // console.log(`page ${pgCurr}/${pageCount} => content=${filteredContent.length} tab=${tabCount} tab/2=${tabHalf}`);

    let pgOffset = pgCurr - tabHalf;
    let pgEnd = pgOffset + tabCount;
    // console.log(` - [${pgOffset}, ${pgEnd}]`);

    pgOffset = Math.max(pgOffset, 0);
    if (pgOffset == 1) pgOffset = 0;
    pgEnd = pgOffset + tabCount;
    // console.log(` - min: [${pgOffset}, ${pgEnd}]`);

    pgEnd = Math.min(pgEnd, pageCount);
    if (pgEnd == pageCount - 1) pgEnd = pageCount;
    pgOffset = pgEnd - tabCount;
    // console.log(` - max: [${pgOffset}, ${pgEnd}]`);

    let showHead = (pgOffset > 0);
    let showFoot = (pgEnd < pageCount - 1);
    // console.log(` - showHead=${showHead} showFoot=${showFoot}`);

    function getColor(dir, entry) {
        const name = pathJoin(dir, entry.name);
        if (name == currentFile) {
            return "text-success fw-bold";
        }
        return "";
    }

    const onSetFileType = (name: string) => (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        const fullPath = pathJoin(currentDir, name);
        const dir = `${currentDir}`.slice(1);
        const accountNumber = dir.split("/")[0].split("-").slice(-1)[0];
        console.log(`${accountNumber} change "${fullPath}" file type to "${value}"`);
        api_set_file_type(accountNumber, fullPath as string, value, userName)
            .then(_ => {
                const newfileTypes = { ...fileTypes };
                newfileTypes[fullPath as string] = value;
                setFileTypes(newfileTypes);
            })
    }

    const dropHandler = (event: React.DragEvent<HTMLDivElement>) => {
        const target = event.currentTarget;
        const subdir = target.dataset["name"] || "";

        event.preventDefault();
        if (currentDir == "/") {
            return;
        }

        console.log(`drop files: subdir=${subdir}`);

        const newFilesToUpload = [...droppedFiles];
        setBorder(null);
        if (event.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            [...event.dataTransfer.items].forEach((item) => {
                // If dropped items aren't files, reject them
                console.log(`drop-handler: item=%o`, item);
                if (item.kind === 'file') {
                    if (typeof item.webkitGetAsEntry === "function") {
                        const entry = item.webkitGetAsEntry();
                        if (entry) {
                            console.log(`drop-handler: item->entry=%o`, entry);
                            if (entry.isFile) {
                                const file = item.getAsFile();
                                if (file) {
                                    newFilesToUpload.push({
                                        file: file,
                                        subdir: subdir,
                                    });
                                }
                            } else if (entry.isDirectory) {
                                addTemporaryError("impossible de déposer des dossiers, veuillez le compresser et le déposer en tant que fichier zip", 3000);
                            }
                        }
                    } else {
                        const file = item.getAsFile();
                        console.log(`drop-handler: item->file=%o`, file);
                        if (file) {
                            newFilesToUpload.push({
                                file: file,
                                subdir: subdir,
                            });
                        }
                    }
                }
            });
        } else {
            // Use DataTransfer interface to access the file(s)
            [...event.dataTransfer.files].forEach((file) => {
                console.log(`drop-handler: file=%o`, file);
                if (file) {
                    newFilesToUpload.push({
                        file: file,
                        subdir: subdir,
                    });
                }
            });
        }
        setDroppedFiles(newFilesToUpload);
    }

    const dragOverHandler = (event: React.DragEvent<HTMLDivElement>) => {
        const target = event.currentTarget;
        const name = target.dataset["name"];

        event.preventDefault();
        if (currentDir == "/") {
            setBorder({
                id: "",
                cn: "border border-danger",
            });
        } else {
            setBorder({
                id: name || "",
                cn: "border border-success",
            });
        }
    }

    const dragEndHandler = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setBorder(null);
    }

    const onUpload = () => {
        const currentEl = uploadInputRef.current;
        if (currentEl === null) {
            return;
        }
        const uploadInputEl = currentEl as HTMLInputElement;
        const newFilesToUpload = [...droppedFiles];
        const files = uploadInputEl.files;
        if (files) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                newFilesToUpload.push({
                    file: file,
                    subdir: "",
                });
            }
            setDroppedFiles(newFilesToUpload);
            uploadInputEl.files = null;
        }
    }

    const table = (
        <>
            <Table className="folder-table" responsive hover>
                <thead onDrop={dropHandler} onDragOver={dragOverHandler}>
                    <tr>
                        <th></th>
                        <th>Nom</th>
                        {showTypes && (
                            <th>Type</th>
                        )}
                        <th>Date</th>
                        <th className="text-end">Taille</th>
                    </tr>
                </thead>
                <tbody onDrop={dropHandler} onDragOver={dragOverHandler}> {/* todo: remove this */}
                    {currentDir !== "/" && (
                        <tr key={-1} className="text-primary" onClick={browse(pathParent(currentDir), "click up")}>
                            <td><RiFolderFill /></td>
                            <td>..</td>
                            <td></td>
                            <td></td>
                        </tr>
                    )}
                    {filteredContent.slice(indexBegin, indexEnd).map((entry, entryIndex) =>
                        entry.is_dir ? (
                            !canRead(entry) ? (
                                <tr key={entry.name + currentPage.toString()} className="text-danger cursor-not-allowed">
                                    <td><RiFolderForbidFill /></td>
                                    <td>{entry.name}</td>
                                    {showTypes && (
                                        <td></td>
                                    )}
                                    <td>{Moment(entry.last_mod).format('HH:mm:SS DD/MM/YYYY')}</td>
                                    <td></td>
                                </tr>
                            ) : (
                                <tr key={entry.name + currentPage.toString()} className={"text-primary cursor-pointer " + (border && border.id == entry.name && border.cn)}
                                    onClick={browse(pathJoin(currentDir, entry.name), `click ${entry.name}`)}
                                    onDragEnd={dragEndHandler} onDragExit={dragEndHandler} onDragLeave={dragEndHandler}
                                    // onDrop={dropHandler} onDragOver={dragOverHandler}
                                    data-name={entry.name}>
                                    <td><RiFolderFill /></td>
                                    <td>{entry.name}</td>
                                    {showTypes && (
                                        <td></td>
                                    )}
                                    <td>{Moment(entry.last_mod).format('HH:mm:SS DD/MM/YYYY')}</td>
                                    <td></td>
                                </tr>
                            )
                        ) : (currentFile.substring(1) == entry.name) ? (
                            <tr key={entry.name + currentPage.toString()} className="fw-bold cursor-default">
                                <td><RiFileList2Line /></td>
                                <td>{entry.name}</td>
                                {showTypes && (
                                    <td>
                                        <select onChange={onSetFileType(entry.name)} value={fileTypes[pathJoin(currentDir, entry.name) as string]}>
                                            <option value=""></option>
                                            {Object.keys(defaultFileTypes).map((type) => <>
                                                <option value={type}>{defaultFileTypes[type]}</option>
                                            </>)}
                                        </select>
                                    </td>
                                )}
                                <td>{Moment(entry.last_mod).format('HH:mm:SS DD/MM/YYYY')}</td>
                                <td className="text-end">{entry.size.toFixed()}</td>
                            </tr>
                        ) : !canRead(entry) ? (
                            <tr key={entry.name + currentPage.toString()} className="text-danger cursor-not-allowed" onDrop={dropHandler} onDragOver={dragOverHandler}>
                                <td><RiFileList2Line /></td>
                                <td>{entry.name}</td>
                                {showTypes && (
                                    <td>
                                        <select onChange={onSetFileType(entry.name)} value={fileTypes[pathJoin(currentDir, entry.name) as string]}>
                                            <option value=""></option>
                                            {Object.keys(defaultFileTypes).map((type) => <>
                                                <option value={type}>{defaultFileTypes[type]}</option>
                                            </>)}
                                        </select>
                                    </td>
                                )}
                                <td>{Moment(entry.last_mod).format('HH:mm:SS DD/MM/YYYY')}</td>
                                <td className="text-end">{entry.size.toFixed()}</td>
                            </tr>
                        ) : (
                            <tr key={entry.name + currentPage.toString()} className="cursor-help"
                            // onDrop={dropHandler} onDragOver={dragOverHandler}
                            >
                                <td><RiFileList2Line /></td>
                                <td className="d-flex">
                                    <input onChange={updateList(currentDir, entry)} type="checkbox" name="" id="" />
                                    {editFile != pathJoin(currentDir, entry.name) ? (
                                        <span className={"ms-2 " + getColor(currentDir, entry)}
                                            onDoubleClick={clickRenameFile(currentDir, entry)}
                                            onClick={openFile(currentDir, entry)}>
                                            {entry.name}
                                        </span>
                                    ) : (
                                        <input className="ms-2 form-control" onBlur={onBlur}
                                            onKeyDown={onInputKeyDown} onInput={onUpdateNewName} value={newName} type="text" />
                                    )}
                                </td>
                                {showTypes && (
                                    <td>
                                        <select onChange={onSetFileType(entry.name)} value={fileTypes[pathJoin(currentDir, entry.name) as string]}>
                                            <option value=""></option>
                                            {Object.keys(defaultFileTypes).map((type) => <>
                                                <option value={type}>{defaultFileTypes[type]}</option>
                                            </>)}
                                        </select>
                                    </td>
                                )}
                                <td>{Moment(entry.last_mod).format('HH:mm:SS DD/MM/YYYY')}</td>
                                <td className="text-end">{entry.size.toFixed()}</td>
                            </tr>
                        )
                    )}
                </tbody>
                {error == 0 && filteredContent.length > 0 && (
                    <tfoot onDrop={dropHandler} onDragOver={dragOverHandler}>
                        <tr>
                            <td colSpan={3}>
                                {(filteredContent.length > itemsPerPage.value) &&
                                    pageCount > tabCount ? (
                                    <Pagination>
                                        {showHead && (
                                            <>
                                                <Pagination.Item onClick={goToPage(0)} key={0} active={0 === currentPage}>
                                                    {1}
                                                </Pagination.Item>
                                                <Pagination.Ellipsis />
                                            </>
                                        )}

                                        {Array.from(new Array(tabCount)).map((_, index) => {
                                            const page = index + pgOffset;
                                            return (
                                                <Pagination.Item onClick={goToPage(page)} key={page} active={page === currentPage}>
                                                    {page + 1}
                                                </Pagination.Item>
                                            );
                                        })}

                                        {showFoot && (
                                            <>
                                                <Pagination.Ellipsis />
                                                <Pagination.Item onClick={goToPage(pageCount - 1)} key={pageCount - 1} active={pageCount - 1 === currentPage}>
                                                    {pageCount}
                                                </Pagination.Item>
                                            </>
                                        )}
                                    </Pagination>
                                ) : (
                                    <Pagination>
                                        {Array.from(new Array(pageCount)).map((_, index) => (
                                            <Pagination.Item onClick={goToPage(index)} key={index} active={index === currentPage}>
                                                {index + 1}
                                            </Pagination.Item>
                                        ))}
                                    </Pagination>
                                )}
                            </td>
                            <td>
                                <Select
                                    value={itemsPerPage}
                                    onChange={(newValue, _) => {
                                        if (newValue) {
                                            setItemsPerPage(newValue);
                                        }
                                    }}
                                    menuPlacement="top"
                                    options={itemsPerPageOptions} />
                            </td>
                        </tr>
                    </tfoot>
                )}
            </Table>
            {error > 0 ? (
                <div className="container text-center">
                    <p className="text-danger">Erreur {error} lors de la lecture de {currentDir}</p>
                </div>
            ) : filteredContent.length == 0 && (
                <p className="text-secondary text-center">Ce dossier est vide</p>
            )}
        </>
    );

    function onDocumentLoadSuccess(pdfDoc) {
        setPdfNumPages(pdfDoc.numPages);
        setPdfDoc(pdfDoc);
    }

    function onPdfNextPage() {
        console.log("pdf next page");
        if (pdfDoc && pdfPageNumber >= pdfDoc.numPages) {
            return;
        }
        setPdfPageNumber((state)=> state + 1)
    }
    function onPdfPreviousPage() {
        console.log("pdf previous page");
        if (pdfDoc && pdfPageNumber <=1) {
            return;
        }
        setPdfPageNumber((state)=> state - 1)
    }

    function onDocumentLoadError(e) {
        console.log("pdf load error", e);
       
    }

    const hasContent = fileContent != null;

    function b2img(): string {
        switch (pathExt(currentFile)) {
            case ".jpg":
            case ".jpeg":
            case ".jfif":
                return 'data:image/jpeg;base64,' + fileContent;
            case ".png":
                return 'data:image/png;base64,' + fileContent;
        }
        return "";
    }

    const isImageExt = (name) => {
        switch (pathExt(name)) {
            case ".jpg":
            case ".jpeg":
            case ".jfif":
            case ".png":
                return true;
        }
        return false;
    }

    const style = {
        width: "100%",
    }

    const preview = (
        <div className="border">
            <p className="bg-secondary text-center p-1 text-white fw-bold">{pathBase(currentFile)}</p>
            {fileError !== 0 ? (
                <>
                    <p className="text-danger text-center">
                        Erreur {fileError} lors du chargement !
                    </p>
                </>
            )
                : !hasContent ? (
                    <>
                        <p className="text-secondary text-center">
                            Chargement du fichier en cours...
                        </p>
                    </>
                ) : (
                    <>
                        {(pathExt(currentFile) == ".pdf") ? (
                            <CustomPdfViewer 
                                fileContent={`${fileContent}`} 
                                onDocumentLoadError={onDocumentLoadError}
                                onDocumentLoadSuccess={onDocumentLoadSuccess}
                                pdfDoc={pdfDoc}
                                pdfPageNumber={pdfPageNumber}
                                pdfNumPages={pdfNumPages}
                                onNextPage={onPdfNextPage}
                                onPreviousPage={onPdfPreviousPage}
                            />
                            
                        ) : (isImageExt(currentFile)) ? (
                            <>
                                <img style={style} src={b2img()} />
                            </>
                        ) : (
                            <>
                                <p className="text-center text-truncate">
                                    {fileContent}
                                </p>
                            </>
                        )}
                    </>
                )}
        </div>
    )

    function handleSearchInputChange(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const value = event.target.value;
        setSearchText(value);
    }

    function handleAccountNumberChange(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const value = event.target.value;
        setAccountNumber(value);
    }

    function handleCreateEntry(_evt: React.MouseEvent<HTMLButtonElement>) {
        api_create(accountNumber)
            .then(res => {
                const newPath = "/" + pathBase(res.dir_name.replace(/\\+/g, '/'));
                if (history && history.pushState) {
                    history.pushState({
                        old: newPath
                    }, "" + res.dir_name, 
                        window.location.pathname +
                        '?token=' + token +
                        '&user=' + userName +
                        '&root=' + newPath)
                }
                setCurrentDir(newPath);
                setFileList([]);
            })
            .catch(err => alert(`create error ${err}`))
            ;
    }

    const width = `${fileProgress > 0 ? fileProgress : 0}%`;
    const progressStyle = {
        width: width,
    };

    function onDownload(_evt: React.MouseEvent) {
        api_download(fileList, userName)
            .then((zipfile) => {
                console.log(`zipfile is at ${zipfile}`);
                const link = document.createElement("a");
                link.download = `${pathBase(zipfile)}`;
                link.href = `${zipfile}`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setFileList([]);
            })
            .catch((error) => {
                alert(`impossible de télécharger le fichier zip : erreur ${error}`);
            })
            ;
    }

    function onRemove(_evt: React.MouseEvent) {
        if (confirm(`Voulez-vous vraiment effacer ces fichiers ?`)) {
            setRemovingFiles(fileList.length);
            api_remove(fileList, userName)
                .finally(() => {
                    setRemovingFiles(-1);
                })
                .then((_) => {
                    const newDirContent = dirContent.filter((entry) => {
                        const name = pathJoin(currentDir, entry.name);
                        return fileList.find((file) => file == name) ? false : true;
                    });
                    setDirContent(newDirContent);
                    setFileList([]);
                })
                .catch((error) => {
                    alert(`impossible d'effacer ces fichiers : erreur ${error}`);
                })
                ;
        }
    }

    return (
        <div className="container pt-1">
            <div className="row">
                <div className="col-8">
                    <Breadcrumb className="ps-2">
                        <Breadcrumb.Item onClick={browse('/', "breadcrumb")}>
                            <RiHome2Fill />
                        </Breadcrumb.Item>
                        {entries.map((entry, index) =>
                            index == entries.length - 1 ? (
                                <Breadcrumb.Item active>{entry}</Breadcrumb.Item>
                            ) : (
                                <Breadcrumb.Item
                                    onClick={browse('/' + entries.slice(0, index + 1).join('/'), "breadcrumb.item")}>{entry}</Breadcrumb.Item>
                            )
                        )}
                    </Breadcrumb>
                </div>
                <div className="col-4">
                    <div className="input-group">
                        <input type="search" value={searchText} onInput={handleSearchInputChange}
                            className="form-control rounded w-100" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
                    </div>
                </div>
            </div>

            {temporaryErrorList.map((entry) => {
                return <div className={"alert " + (entry.warning ? "alert.warning" : "alert-danger")} role="alert">
                    {entry.error}
                </div>
            })}

            {fileList.length > 0 ? (
                <div className="row">
                    <div className="col">
                        <button onClick={onRemove} className="btn btn-danger">Effacer {fileList.length} fichiers</button>
                        &nbsp;
                        <button onClick={onDownload} className="btn btn-success">Télécharger {fileList.length} fichiers</button>
                    </div>
                </div>
            ) : currentDir !== "/" && showUpload && (
                <div className="row">
                    <div id="upload" ref={uploadRef} className="col">
                        <button className="btn btn-primary">Déposer des fichiers</button>
                        <input ref={uploadInputRef} onChange={onUpload} type="file" multiple />
                    </div>
                </div>
            )}

            <div className={"row " + (border && border.id == "" && border.cn)}>
                {uploadingFile ? (
                    <div className="col-12">
                        <p>{uploadingFile.file.name} ({uploadingFile.file.size} bytes)</p>
                        <div className="progress">
                            <div className="progress-bar" style={progressStyle}></div>
                        </div>
                        <p>loading {fileProgress.toFixed(2)} %</p>
                    </div>
                ) : (currentFile == "") ? (
                    <div className="col-12">
                        {table}
                    </div>
                ) : (
                    <>
                        <div className="col-8">
                            {table}
                        </div>
                        <div className="col-4">
                            {preview}
                        </div>
                    </>
                )}
            </div>

            <div className="row">
                <div className="col-8">
                    <input type="text" onInput={handleAccountNumberChange} />
                </div>
                <div className="col-4">
                    <button className="Create Entry" onClick={handleCreateEntry}>Create account directory</button>
                </div>
            </div>

            {(unzipingFile != "") && (
                <div aria-live="polite" aria-atomic="true" role="alert">
                    <div className="my-toast-container">
                        <div className="my-toast">
                            <div className="my-toast-header">
                                <div className="col">
                                    <strong className="me-auto">Extraction du fichier zip</strong>
                                </div>
                                <div className="col text-end">
                                    <div className="spinner-grow text-warning" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                            <div className="my-toast-body">
                                {unzipingFile}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {(removingFiles > 0) && (
                <div aria-live="polite" aria-atomic="true" role="alert">
                    <div className="my-toast-container">
                        <div className="my-toast">
                            <div className="my-toast-header">
                                <div className="col">
                                    <strong className="me-auto">Suppression de fichiers</strong>
                                </div>
                                <div className="col text-end">
                                    <div className="spinner-grow text-danger" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                            <div className="my-toast-body">
                                {removingFiles} en cours
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Browse;

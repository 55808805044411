export function pathExplode(dir: String): String[] {
    if (dir == "" || dir[0] !== "/") {
        return [];
    }
    return dir.substring(1).split('/');
}

export function pathParent(dir: String): String {
    const entries = pathExplode(dir);
    if (entries.length < 2) {
        return "/";
    }
    return '/' + entries.slice(0, entries.length - 1).join("/");
}

export function pathJoin(dir: String, name: String): String {
    if (dir == "" || dir[0] !== "/") {
        return "/" + name;
    }
    if (dir.endsWith("/")) {
        return dir + '' + name;
    }
    return dir + '/' + name;
}

export function pathBase(dir: String): String {
    const entries = pathExplode(dir);
    if (entries.length == 0)
        return "";
    return entries[entries.length - 1];
}

export function pathExt(p: String): String {
    return p.substring(p.lastIndexOf('.')).toLowerCase();
}
export interface DirContent {
    name: string,
    size: number,
    last_mod: Date,
    is_dir: boolean,
    can_read: boolean,
}

function getURLParameter(name) {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(name);
}

export async function api_open(dir: String, user: String): Promise<String> {
    return new Promise((resolve, reject) => {
        const myHeaders = new Headers();
        let token = getURLParameter("token");
        if (token) {
            myHeaders.set('Authorization', 'Bearer ' + token);
        }
        myHeaders.set('Content-Type', 'application/json');

        const op = "open";
        const myData = {
            op,
            dir,
            user,
        };

        const myInit: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default',
            body: JSON.stringify(myData),
        };

        const myRequest = new Request(`/api/v1/browse/`, myInit);

        fetch(myRequest)
            .then(async (res) => {
                if (res.status < 200 || res.status > 299) {
                    console.error(`api_open: server returned ${res.status}`);
                    reject(res.status);
                    return
                }

                res.text()
                    .then(buf => {
                        resolve(buf);
                    })
                    .catch(err => {
                        console.error(`api_open: caught error ${err}`);
                        reject(-1);
                    })
                    ;
            })
            .catch(err => {
                console.error(`api_open: caught error ${err}`);
                reject(-1);
            })
            ;
    });
}

export async function api_browse(dir: String, user: String): Promise<DirContent[]> {
    return new Promise((resolve, reject) => {
        const myHeaders = new Headers();
        let token = getURLParameter("token");
        if (token) {
            myHeaders.set('Authorization', 'Bearer ' + token);
        }
        myHeaders.set('Content-Type', 'application/json');

        const op = "enum";
        const myData = {
            op,
            dir,
            user,
        };

        const myInit: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default',
            body: JSON.stringify(myData),
        };

        const myRequest = new Request(`/api/v1/browse/`, myInit);

        fetch(myRequest)
            .then(async (res) => {
                if (res.status < 200 || res.status > 299) {
                    console.error(`api_browse: server returned ${res.status}`);
                    reject(res.status);
                    return
                }

                if (res.headers.get('Content-Type') != 'application/json') {
                    console.error(`api_browse: unexpected content-type`);
                    reject(415);
                    return
                }

                res.json()
                    .then(resolve)
                    .catch(err => {
                        console.error(`api_browse: caught error ${err}`);
                        reject(-1);
                    })
                    ;
            })
            .catch(err => {
                console.error(`api_browse: caught error ${err}`);
                reject(-1);
            })
            ;
    });
}

export interface DirName {
    dir_name: String,
}

export async function api_create(accountNumber: String): Promise<DirName> {
    return new Promise((resolve, reject) => {
        const myHeaders = new Headers();
        let token = getURLParameter("token");
        if (token) {
            myHeaders.set('Authorization', 'Bearer ' + token);
        }
        myHeaders.set('Content-Type', 'application/json');

        const myInit: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default',
        };

        const myRequest = new Request(`/api/v1/browse/${accountNumber}`, myInit);

        fetch(myRequest)
            .then(async (res) => {
                if (res.status < 200 || res.status > 299) {
                    console.error(`api_browse: server returned ${res.status}`);
                    reject(res.status);
                    return
                }

                if (res.headers.get('Content-Type') != 'application/json') {
                    console.error(`api_browse: unexpected content-type`);
                    reject(415);
                    return
                }

                res.json()
                    .then(resolve)
                    .catch(err => {
                        console.error(`api_browse: caught error ${err}`);
                        reject(-1);
                    })
                    ;
            })
            .catch(err => {
                console.error(`api_browse: caught error ${err}`);
                reject(-1);
            })
            ;
    });
}

export async function api_rename(src: String, dst: String, user: String): Promise<String> {
    return new Promise((resolve, reject) => {
        const myHeaders = new Headers();
        let token = getURLParameter("token");
        if (token) {
            myHeaders.set('Authorization', 'Bearer ' + token);
        }
        myHeaders.set('Content-Type', 'application/json');

        const op = "rename";
        const myData = {
            op,
            src,
            dst,
            user,
        };

        const myInit: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default',
            body: JSON.stringify(myData),
        };

        const myRequest = new Request(`/api/v1/browse/`, myInit);

        fetch(myRequest)
            .then(async (res) => {
                if (res.status < 200 || res.status > 299) {
                    console.error(`api_open: server returned ${res.status}`);
                    reject(res.status);
                    return
                }

                res.text()
                    .then(buf => {
                        resolve(buf);
                    })
                    .catch(err => {
                        console.error(`api_open: caught error ${err}`);
                        reject(-1);
                    })
                    ;
            })
            .catch(err => {
                console.error(`api_open: caught error ${err}`);
                reject(-1);
            })
            ;
    });
}

export async function api_download(files: String[], user: String): Promise<String> {
    return new Promise((resolve, reject) => {
        const myHeaders = new Headers();
        let token = getURLParameter("token");
        if (token) {
            myHeaders.set('Authorization', 'Bearer ' + token);
        }
        myHeaders.set('Content-Type', 'application/json');

        const op = "download";
        const myData = {
            op,
            files,
            user,
        };

        const myInit: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default',
            body: JSON.stringify(myData),
        };

        const myRequest = new Request(`/api/v1/browse/`, myInit);

        fetch(myRequest)
            .then(async (res) => {
                if (res.status < 200 || res.status > 299) {
                    console.error(`api_open: server returned ${res.status}`);
                    reject(res.status);
                    return
                }

                res.text()
                    .then(buf => {
                        resolve(buf);
                    })
                    .catch(err => {
                        console.error(`api_open: caught error ${err}`);
                        reject(-1);
                    })
                    ;
            })
            .catch(err => {
                console.error(`api_open: caught error ${err}`);
                reject(-1);
            })
            ;
    });
}

export async function api_remove(files: String[], user: String): Promise<String> {
    return new Promise((resolve, reject) => {
        const myHeaders = new Headers();
        let token = getURLParameter("token");
        if (token) {
            myHeaders.set('Authorization', 'Bearer ' + token);
        }
        myHeaders.set('Content-Type', 'application/json');

        const op = "remove";
        const myData = {
            op,
            files,
            user,
        };

        const myInit: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default',
            body: JSON.stringify(myData),
        };

        const myRequest = new Request(`/api/v1/browse/`, myInit);

        fetch(myRequest)
            .then(async (res) => {
                if (res.status < 200 || res.status > 299) {
                    console.error(`api_open: server returned ${res.status}`);
                    reject(res.status);
                    return
                }

                res.text()
                    .then(buf => {
                        resolve(buf);
                    })
                    .catch(err => {
                        console.error(`api_open: caught error ${err}`);
                        reject(-1);
                    })
                    ;
            })
            .catch(err => {
                console.error(`api_open: caught error ${err}`);
                reject(-1);
            })
            ;
    });
}

export async function api_get_file_types(accountNumber: string): Promise<Object> {
    return new Promise((resolve, reject) => {
        const myHeaders = new Headers();
        let token = getURLParameter("token");
        if (token) {
            myHeaders.set('Authorization', 'Bearer ' + token);
        }
        myHeaders.set('Content-Type', 'application/json');

        const myInit: RequestInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default',
        };

        const myRequest = new Request(`/api/v1/browse/${accountNumber}/files`, myInit);

        fetch(myRequest)
            .then(async (res) => {
                if (res.status < 200 || res.status > 299) {
                    console.error(`api_get_file_types: server returned ${res.status}`);
                    reject(res.status);
                    return
                }

                if (res.headers.get('Content-Type') != 'application/json') {
                    console.error(`api_get_file_types: unexpected content-type`);
                    reject(415);
                    return
                }

                res.json()
                    .then(resolve)
                    .catch(err => {
                        console.error(`api_get_file_types: caught error ${err}`);
                        reject(-1);
                    })
                    ;
            })
            .catch(err => {
                console.error(`api_get_file_types: caught error ${err}`);
                reject(-1);
            })
            ;
    });
}

export async function api_set_file_type(accountNumber: string, file_path: string, file_type: string, user_name: string): Promise<Object> {
    return new Promise((resolve, reject) => {
        const myHeaders = new Headers();
        let token = getURLParameter("token");
        if (token) {
            myHeaders.set('Authorization', 'Bearer ' + token);
        }
        myHeaders.set('Content-Type', 'application/json');

        const myData = {
            file_path,
            file_type,
            user_name,
        };

        const myInit: RequestInit = {
            method: 'PUT',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default',
            body: JSON.stringify(myData),
        };

        const myRequest = new Request(`/api/v1/browse/${accountNumber}/files`, myInit);

        fetch(myRequest)
            .then(async (res) => {
                if (res.status < 200 || res.status > 299) {
                    console.error(`api_get_file_types: server returned ${res.status}`);
                    reject(res.status);
                    return
                }

                resolve(true);
            })
            .catch(err => {
                console.error(`api_get_file_types: caught error ${err}`);
                reject(-1);
            })
            ;
    });
}
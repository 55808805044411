import React from "react";
import ReactDOM from "react-dom/client";

import Browse from "../../components/browse/Browse";
import ChooseLang from "../../components/header/Header";
import GlobalStateProvider from "../../components/store/store";

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <App></App>
    </React.StrictMode>
);

function App() {
    return <div className="page-browse">
        <GlobalStateProvider>
            <ChooseLang />
            <Browse />
        </GlobalStateProvider>
    </div>;
}